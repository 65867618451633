// fontawesome
$fa-font-path: "../../../node_modules/font-awesome/fonts" !default;
@import 'node_modules/font-awesome/scss/font-awesome.scss';

// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);
// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

// Font-awesome
$fa-font-path: "../../../node_modules/font-awesome/fonts" !default;
@import 'node_modules/font-awesome/scss/font-awesome.scss';